import React, { useState } from 'react';

import { Flex, Image } from '@chakra-ui/react';

import block from '../../../../assets/images/block_ball.svg';
import confirm from '../../../../assets/images/confirm_ball_small.svg';
import send from '../../../../assets/images/send.svg';
import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';
import ModalSendTrip from '../../components/Modals/ModalSendTrip';

const ProfileButtons = ({ user, refresh }) => {
  const [load, setLoad] = useState(false);

  const changeStatus = (identifier, transition, data = {}) => {
    setLoad(true);
    requests.changeStatusUser(identifier, transition).then(() => {
      refresh();
      setLoad(false);
    });
  };

  const buttons = () => {
    if (user?.status == 'EM_ANALISE') {
      return (
        <DefaultModal
          label="Ativar usuário"
          ctaButton={
            <Image
              src={confirm}
              alt="Aprovar usuário"
              boxSize="41px"
              opacity="1"
              cursor="pointer"
              transition="0.2s"
              _hover={{
                opacity: '0.5',
              }}
            />
          }
          title={'Confirmar ativação'}
          loading={load}
          subtitle={
            <>
              Você tem certeza que deseja ativar esse usuário?
              <br /> Valide corretamente antes de realizar a ativação.
            </>
          }
          buttonColor="green"
          txtButton="Ativar usuário"
          enabled={true}
          callback={(decision) => {
            if (decision) {
              changeStatus(user?.identifier, 'approve');
            }
          }}
        />
      );
    } else if (user?.status == 'ATIVO') {
      return (
        <>
          <DefaultModal
            label="Suspender"
            ctaButton={<Image src={block} />}
            title={'Certeza que deseja suspender?'}
            loading={load}
            subtitle={
              <>
                Você tem certeza que deseja suspender esse motorista?
                <br /> Seu acesso a plataforma será cancelado e ele não receberá viagens.
              </>
            }
            buttonColor="red"
            txtButton="Suspender"
            callback={(decision) => {
              if (decision) {
                changeStatus(user?.identifier, 'reject');
              }
            }}
          />
          <ModalSendTrip icon={send} enabled={true} w="auto" user={user} />
        </>
      );
    }

    return;
  };

  return (
    <Flex gap="8px">
      {buttons()}
      {/*<Image
        src={call}
        alt="Enviar viagem"
        filter="saturate(0)"
        opacity="0.5"
        cursor="pointer"
        transition="0.2s"
        _hover={{
          filter: 'saturate(1)',
          opacity: '1',
        }}
        // onClick={onOpen}
      />*/}
    </Flex>
  );
};

export default ProfileButtons;
